import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'

import { Background, ModalContent } from './style'
import { lockOverflow, unlockOverflow } from 'src/utils/documentUtils'

type ModalProps = {
  children: React.ReactNode;
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  locationToRender: Element | DocumentFragment;
  zIndexOverlay?: number;
};

export const Modal = ({
  children,
  isModalOpen,
  setIsModalOpen,
  locationToRender,
  zIndexOverlay,
}: ModalProps) => {
  const closeModal = () => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    if (isModalOpen) lockOverflow()

    return () => {
      unlockOverflow()
    }
  }, [ isModalOpen ])

  return (
    isModalOpen && (
      <>
        {createPortal(
          <ModalContent>
            <Background onClick={closeModal} zIndexOverlay={zIndexOverlay} />
            {children}
          </ModalContent>,
        locationToRender,
      )}
      </>
    )
  )
}
